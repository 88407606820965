@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');
body{
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    background-color: #CBD3DB;
    color: #3E4650;
}

/* ========================= CSS for LOGIN ========================= */
.videos{
    flex: 1;
}

.frameVideo{
    position: relative;
    grid-area: content;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.logo_web{
    width: 300px;
    height: 60px;
}

.frame_login{
    position: absolute;
    width: 496px;
    height: 414px;
    margin-top: 190px;
    background: #F5F7FA;
    box-shadow: 0px 0px 6px rgba(62, 70, 80, 0.16);
    border-radius: 16px;
}

.frame_login h1{
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #0B0B0D;
}

.sub_title{
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    width: 302px;
    opacity: 0.8;
}

.frame_login p{
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
}

.button_login p{
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: white;
}

/* ========================= CSS for LOGIN ========================= */

/* ========================= CSS for ROLE ========================= */

.circle_role{
    border-radius: 50px;
    height: 100px;
    width: 100px;
    background: #CBD3DB;
    background-size: 200% 100%;
    transition: all 2s ease;
}

.circle_role:hover{
    background: linear-gradient(#5A7F7D 0%, #272C48 99.98%, #252F46 99.99%);
}

/* ========================= CSS for FACTSHEET ========================= */
.sidenav{
    background-color: #E2E8F0;
    height: 100%;
    width: 240px;
    position: fixed;
    overflow: auto;
    z-index: 1;
    /* padding-top: 240px; */
}

.sidenav-list{
    padding-top: 100px;
    padding-left: 10px;
}

.sidenav-list p{
    font-family: 'Nunito Sans', sans-serif;
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    margin-left: 10px;
    padding: 0px;
    line-height: 22px;
}

.sidenav-list ul, li{
    padding-left: 10px;
}   

.icon-list{
    background: linear-gradient(180deg, #5A7F7D 0%, #272C48 99.98%, #252F46 99.99%);
}

.icon-list-done{
    background: #64C06D;
}

.responsive-iframe{
    width: 100%;
    min-height: 530px;
    border: none;
}

.main-page{
    width: auto;
    margin-left: 240px;
    padding-top: 120px;
    padding-bottom: 60px;
    padding-left: 0px;
    padding-right: 0px;
    background: #F5F7FA;
}

/* .main-page-prepost{
    width: auto;
    margin-left: 240px;
    padding-top: 120px;
    padding-bottom: 60px;
    padding-left: 0px;
    padding-right: 0px;
    background: #F5F7FA;
} */


.text-field {
    background-color: #E2E8F0 !important;
}

.background{
    background-image: url(../img/Hero.svg);
    background-color: #f8f9fa;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: right bottom;
}

.background-ishoma{
    background-color: #f8f9fa;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: right bottom;
}

.timer{
    background: #CBD3DB;
    border-radius: 6px;
    width: 101px;
    height: 43px;
    padding: 12px 16px;
    flex: none;
}

.timer p{
    font-size: 14px;
    line-height: 19px;
}

.feedback th{
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
}

.ck-editor__editable_inline {
    min-height: 300px;
}

.btn-upload{
    border: 1px solid rgba(62, 70, 80, 0.32);
    width: 158px;
    height: 158px;
    box-sizing: border-box;
    padding: 0px;
    border-radius: 50%;
    background-color: #E2E8F0 !important;
    overflow: hidden;
}

#upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

label{
    cursor: pointer;
}

.overlay-upload-photo{
    position: absolute;
    top: 0px;
    left: 20px;
    opacity: 0;
    width: 158px;
    height: 158px;
    object-fit: fill;
}

.zindex-10{
    z-index: 10;
}

.border-notes{
    border-top: 7px #3E8392  solid;
}

.border-notes-list{
    min-height: 25px;
}

.bg-notes{
    background: linear-gradient(#5A7F7D 0%, #272C48 99.98%, #252F46 99.99%);
}

.btn-add-notes{
    color: 'white';
    background-color: #3E8392;
}

.btn-add-notes:hover{
    transition: 0.2s ease-in;
    background-color: #2b5f6b;
}

.inherit{
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.recording{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;

    width: 126px;
    height: 40px;
    left: 0px;
    top: 8px;

    background: rgba(233, 46, 58, 0.16);
    border-radius: 8px;

    margin: 16px 0px;
}

.not-recording{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;

    width: 126px;
    height: 40px;
    left: 0px;
    top: 8px;

    /* background: rgba(233, 46, 58, 0.16);
    border-radius: 8px; */

    margin: 16px 0px;
}

.marker{
    left: 0px;
    top: 8px;
}
.ping {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    animation: load 1.5s ease-out infinite;
}

@keyframes load {
    0% {
        background: #DC2626;
        border: 0px solid #991B1B;
   }
    50% {
        background: #DC2626;
        border: 5px solid #991B1B;
   }
    75% {
        background: #DC2626;
        border: 5px solid #991B1B;
   }
    100% {
        background: #DC2626;
        border: 0px solid #991B1B;
   }
}

.selfie-line-guideline{
    background: url('../img/Video-Tile.png');
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
    position: absolute;
    opacity: 0.1;
    z-index: 10;
}

/* css for stop and leave btn controler on vision speech */
.ch-control-bar-item-label {
    width: max-content;
}